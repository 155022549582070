var viewWidth = window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

$(document).ready(function () {
    'use strict';
    /* Owl-Carousel */
    if ($().owlCarousel) {
        (function () {
            /* New Slider */
            if ($('.new-slider__slider').length) {
                var owlHero = $('.new-slider__slider'),
                    owlHeroLoop = true,
                    owlHeroNav = true,
                    owlHeroDots = true,
                    owlAutoHeight = false;  // default false - with true, the items can be of different heights

                if (owlHero.find('.new-slider__item').length > 1) {
                    if (owlHero.attr('data-loop') !== undefined) {
                        owlHeroLoop = owlHero.attr('data-loop');
                        if (owlHeroLoop === 'false') {
                            owlHeroLoop = false;
                        }
                    }

                    if (owlHero.attr('data-nav') !== undefined) {
                        owlHeroNav = owlHero.attr('data-nav');
                        if (owlHeroNav === 'false') {
                            owlHeroNav = false;
                        }
                    }

                    if (owlHero.attr('data-dots') !== undefined) {
                        owlHeroDots = owlHero.attr('data-dots');
                        if (owlHeroDots === 'false') {
                            owlHeroDots = false;
                        }
                    }

                    if (owlHero.attr('data-autoheight') !== undefined) {
                        owlAutoHeight = owlHero.attr('data-autoheight');
                        if (owlAutoHeight === 'true') {
                            owlAutoHeight = true;
                        }
                    }

                    owlHero.owlCarousel({
                        items: 1,
                        loop: owlHeroLoop,
                        margin: 10,
                        nav: owlHeroNav,
                        dots: owlHeroDots,
                        animateOut: 'fadeOut',
                        autoHeight: owlAutoHeight,
                        navText: ['', '']
                    });
                } else {
                    owlHero.show();
                }

                /* 28.05.2020: not needed anymore
                $('.more-news-preview-inner .more-news-item').on('click', function (e) {
                    e.preventDefault();
                    var elem_index = $(this).index();
                    var jres_breakpoint = jRes.getBreakpoint();
                    owlHero.trigger('to.owl.carousel', elem_index);

                    if (jres_breakpoint.indexOf('mobile') >= 0 && $('.new-slider__more-news-wrapper').hasClass('open')) {
                        $('.js-slider-more-news-btn').trigger('click');
                    }
                });
                */
            }
            /* New Slider:end */


            /* Testimonial Slider */
            var owlTestimonial = $('.new-testimonial__slider'),
                owlTestimonialLenght = owlTestimonial.length,
                owlTestimonialLoop,
                i;

            if (owlTestimonialLenght) {
                for (i = 0; i < owlTestimonialLenght; i = i + 1) {
                    owlTestimonialLoop = true;
                    if ($(owlTestimonial[i]).attr('data-loop') !== undefined) {
                        owlTestimonialLoop = $(owlTestimonial[i]).attr('data-loop');
                        if (owlTestimonialLoop === 'false') {
                            owlTestimonialLoop = false;
                        }
                    }

                    $(owlTestimonial[i]).owlCarousel({
                        loop: owlTestimonialLoop,
                        items: 1,
                        margin: 10,
                        nav: true,
                        dots: false,
                        navText: ['', '']
                    });
                }
            } else {
                owlTestimonial.show();
            }
            /* Testimonial Slider:end */
        }());
    }
    /* Owl-Carousel:end */
    /* REMODAL TRIGGER */

    $('[data-remodal-id=search]').show();  // initially the div is hidden
    var remSearch = $('[data-remodal-id=search]').remodal();

    $('.new-slider__search').on('click', function (event) {
        event.preventDefault();
        var offset = $(this).offset().top - 50;
        $("html, body").animate({ scrollTop: offset + 'px' }, 300);
        setTimeout(function () { remSearch.open(); }, 300);

    });

    $(document).on('opened', '.remodal', function () {
        $('#livesearch').focus();
    });


    // Live Search


    $('input#livesearch').typeahead([{
        name: 'salkLiveSearch',
        remote: {
            url: 'ttsuche.php?q=%QUERY',
            cache: false,
            dataType: 'json',
        },
        template: [
            '<img src="{{image}}" alt="{{titel}}" width="100" height="50" align="left" />',
            '<div class="tt-salk-titel">{{titel}}</div>',
            '<div class="tt-salk-beschreibung">{{beschreibung}}</div><div class="tt-salk-clear"></div>'
        ].join(''),
        engine: Hogan,
        limit: 10
    }]).on('typeahead:selected', function ($e, data) {
        window.location = data.url;
    });

    $('.js-slider-more-news-btn').on('click', function (e) {
        e.preventDefault();
        var jres_breakpoint = jRes.getBreakpoint();
        $(this).closest('.new-slider__more-news-wrapper').toggleClass('open');

        if (jres_breakpoint.indexOf('mobile') >= 0 && $('.new-slider__more-news-wrapper').hasClass('open')) {
            $('.new-slider__slider').hide();
        } else {
            $('.new-slider__slider').show();
        }
    });


    /* set same height for each element with attribute data-sameheight */
    function setSameheight(ele) {
        var breakpoint = jRes.getBreakpoint();
        var heighest = 0;

        if (breakpoint.indexOf('mobile') >= 0) {
            $(ele).removeAttr('style');
        } else {
            ele = ele  + ':visible';  // only work with visible elements here

            if ($(ele).hasClass('js-sameheight-in-row')) {  // if class set, sameheight will calculated for items in each row!
                if ($(ele).closest('.js-sameheight-in-row-parent').length && $(ele).closest('.js-sameheight-in-row-parent').find('.js-sameheight-in-row-col-parent').length > 1) {
                    var elements_in_a_row = 1;
                    var count_elem_cols = $(ele).closest('.js-sameheight-in-row-parent').find(ele).length;
                    var container_width = Math.floor($(ele).closest('.js-sameheight-in-row-parent')[0].getBoundingClientRect().width);  // FF liefert bei 33% Col-width 366.667, Chrome und IE 366.656, daher vorher abrunden
                    var $elem_col = $(ele).closest('.js-sameheight-in-row-col-parent');
                    var elem_col_width = Math.floor($elem_col[0].getBoundingClientRect().width);  // FF liefert bei 33% Col-width 366.667, Chrome und IE 366.656, daher vorher abrunden
                    var last_elem_index_loop = 0;
                    elements_in_a_row = Math.floor(container_width / elem_col_width);

                    $(ele).each(function (i) {
                        i++;
                        var $element = $(this);

                        $element.css("height", "auto");
                        if ($element.height() > heighest) {
                            heighest = $element.height();
                        }

                        if (i == count_elem_cols) {
                            $(ele).slice(last_elem_index_loop, i).height(heighest);
                            return false;
                        }

                        if (i % elements_in_a_row == 0) {
                            $(ele).slice(i - elements_in_a_row, i).height(heighest);
                            heighest = 0;
                            last_elem_index_loop = i;
                        }
                    });
                }
            } else {
                $(ele).each(function () {
                    var $element = $(this);

                    $element.css("height", "auto");
                    if ($element.height() > heighest) {
                        heighest = $element.height();
                    }
                });
                $(ele).height(heighest);
            }
        }
    }

    function resizeSameheight() {
        var sameheight = $("[data-sameheight]"),
            sameheightclass = [],
            i;

        for (i = 0; i < sameheight.length; i = i + 1) {
            sameheightclass.push($(sameheight[i]).attr("data-sameheight"));
        }
        sameheightclass = $.unique(sameheightclass);
        for (i = 0; i < sameheightclass.length; i = i + 1) {
            setSameheight(sameheightclass[i]);
        }
    }
    if ($("[data-sameheight]").length) {
        (function () {
            var resizetimer;

            $(window).on("resize", function () {
                clearTimeout(resizetimer);
                resizetimer = setTimeout(resizeSameheight, 250);
            });

            resizeSameheight();
        }());
    }
    /* set same height for each element with attribute data-sameheight:end */


    /* overview page filter, load more and mixitup functions */
    if($('.js-new-overview-load-more-btn').attr('data-item-offset') !== undefined){
        $('.new-overview-content-item').addClass('not-mix');
    }

    $('.js-show-hide-filter').on('click', function(){
        $('.new-overview-filter-wrapper').toggleClass('visible-phone');
        $(this).blur();
    });

    $('.new-overview-filter-wrapper .filter').on('click', function(){
        if(!$(this).hasClass('active')){
            $('.new-overview-filter-wrapper .filter').removeClass('active');
            $(this).addClass('active');
            loadMoreOverviewItems();
        }
    });

    function loadMoreOverviewItems(){
        var filter_class = 'all';
        if($('.new-overview-filter-wrapper .filter.active').length){
            filter_class = $('.new-overview-filter-wrapper .filter.active').data('filter');
        }
        var filter_class_val = filter_class !== undefined ? (filter_class == 'all' ? '' : filter_class)  : '';
        var items_to_show = parseInt($('.js-new-overview-load-more-btn').attr('data-load-elements'));  // items to show per load
        var item_offset = parseInt($('.js-new-overview-load-more-btn').attr('data-item-offset'));  // last element position shown
        var max_item_pos = parseInt($('.new-overview-content-item' + filter_class_val).length ? $('.new-overview-content-item' + filter_class_val).length : 0); // last element pos of chosen class
        var max_item_shown_pos = parseInt($('.new-overview-content-item' + filter_class_val + ':visible').length ? $('.new-overview-content-item' + filter_class_val + ':visible').length : 0);
        var check_tmp = (item_offset > items_to_show ? item_offset : items_to_show);
    
        if(max_item_shown_pos < check_tmp){
            max_item_shown_pos = check_tmp;
        }

        if(item_offset < 1){
            item_offset = item_offset + items_to_show;
            $('.js-new-overview-load-more-btn').attr('data-item-offset', item_offset);
        }

        $('.js-new-overview-load-more-btn').addClass('displaynone');
        $('.new-overview-content-item' + filter_class_val).slice(0, item_offset).removeClass('not-mix').addClass('mix');
        $('.new-overview-content-item' + filter_class_val).slice(max_item_shown_pos).removeClass('mix').addClass('not-mix').removeAttr('style');

        $('.new-overview-content').mixItUp({
            load: {
                target: '.mix',
                filter: filter_class,
                sort: false
            },
            animation: {
                effects: 'fade scale translateX stagger',
                duration: 350
            },
            callbacks: {
                onMixStart: function(state){
                    $('.new-overview-filter-wrapper .filter').attr('disabled', true);
                    //$('.js-sameheight-in-row').removeAttr('style');
                },
                onMixEnd: function(state){
                    if(item_offset >= max_item_pos){ // no more items to load - hide button
                        $('.js-new-overview-load-more-btn').addClass('displaynone');
                    }else{ // more items to load - show button
                        $('.js-new-overview-load-more-btn').removeClass('displaynone');
                    }

                    $('.new-overview-content').mixItUp('destroy');
                    $('.new-overview-filter-wrapper .filter').attr('disabled', false);
                    resizeSameheight(); 
                }
            }
        });
    }
    // on load show filter-items
    loadMoreOverviewItems();

    $('.js-new-overview-load-more-btn').on('click', function(){
        var items_to_show = parseInt($('.js-new-overview-load-more-btn').attr('data-load-elements'));  // items to show per load
        var item_offset = parseInt($('.js-new-overview-load-more-btn').attr('data-item-offset'));  // last element position shown

        $('.js-new-overview-load-more-btn').attr('data-item-offset', item_offset + items_to_show);
        loadMoreOverviewItems();
    });
    /* overview page filter, load more and mixitup functions:end */


    /* youtube functions for overview page */
    if($('.js-is-youtube-video-page').length){
        (function() {
            var players = [];
            //load the IFrame Player API code asynchronously
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            $(".fancybox-youtube").attr('rel', 'gallery').fancybox({
                openEffect  : 'none',
                closeEffect : 'none',
                nextEffect  : 'none',
                prevEffect  : 'none',
                padding     : 0,
                margin      : 30,
                wrapCSS     : 'youtube-fancybox-wrapper',
                width       : 1280,
                aspectRatio : true,
                beforeShow  : function() {
                    stopAllYoutubeVideos();
                }
            });
        
            function stopAllYoutubeVideos(){
                for (var i in players) {
                    var player = players[i];
                    player.stopVideo();
                }
            }
        
            //gets called once the player API has loaded
            window.onYouTubePlayerAPIReady = function() {
                $('.js-iframe-youtube-video').each(function() {
                    var frame = $(this);
                    //create each instance using the individual iframe id
                    var player = new YT.Player(frame.attr('id'), {
                        //host: 'https://www.youtube.com',
                        //origin: "http://localhost:8080",
                        //widget_referrer: "http://localhost:8080",
                        events: {
                            'onStateChange': onPlayerStateChange
                        }
                    });
        
                    players.push(player);
                });
            }
        
            function onPlayerStateChange(event) {
                if (event.data == YT.PlayerState.PLAYING) {
                    // also can use video-id to check - event.target.getVideoData()['video_id']
                    var temp = $(event.target.getIframe()).attr('id');
        
                    for (var i = 0; i < players.length; i++) {
                        var current_iframe_id = $(players[i].getIframe()).attr('id');
                        // also can use video-id to check - players[i].getVideoData()['video_id']
                        if (current_iframe_id != temp) 
                            players[i].stopVideo();
                    }
                }
            }
        })();
    }
    /* youtube functions for overview page:end */

    /* readspeaker stuff */
    if ($('.readspeaker-wrapper').length) {
        (function () {
            var elements_to_skip_rs = 'img, noscript, .sr-only, .app-hint, .video-list .video-description, .video-player-container, .video-share-container, .preview-search, .embed-responsive, .sub-menu, #footer, #footer-mobile, .rside, #fo-im, #preview-noresult, .new-map, .js-plyr'; // these elements should not be read by RS
            var elements_to_remove_preserve_rs = ''; // from these elements .rs_preserve class should be removed
            var $sameheight_elems = $('[data-sameheight]');
            var elements_to_preserve_rs = 'img[data-src], [class*="js-"], .video-list, .video-player-container, .video-share-container, .preview-search, .video-container .video-item, .tooltipstered, .new-overview-filter-wrapper, .embed-responsive, #footer, #footer-mobile, .rside, #fo-im, #preview-noresult, .new-map, .js-plyr'; // these elements should not be manipulated by RS

            $sameheight_elems.each(function (index) {
                elements_to_remove_preserve_rs += $(this).data('sameheight') + ',';
            });
            elements_to_remove_preserve_rs += '.new-overview-content, .new-overview-content-item';
            elements_to_remove_preserve_rs = elements_to_remove_preserve_rs.replace(/(^,)|(,$)/g, ""); // remove leading and trailing comma
            elements_to_preserve_rs = elements_to_preserve_rs.replace(/(^,)|(,$)/g, ""); // remove leading and trailing comma
            elements_to_skip_rs = elements_to_skip_rs.replace(/(^,)|(,$)/g, ""); // remove leading and trailing comma

            $(elements_to_skip_rs).addClass('rs_skip');
            $(elements_to_preserve_rs).addClass('rs_preserve');
            $(elements_to_remove_preserve_rs).removeClass('rs_preserve');
        }());

        $(document).ajaxStart(function () {
            $('.readspeaker-wrapper').css({
                'opacity': '.4',
                'pointer-events': 'none'
            });
        });

        $(document).ajaxComplete(function () {
            $('.readspeaker-wrapper').removeAttr('style');
        });
    }
    function skipReadingOwlClonedElements(owl_elem) {
        $(owl_elem).find('.owl-item.cloned').addClass('rs_skip');
    }
    /* readspeaker stuff:end */

    /* plyr stuff */
    if ($('.js-plyr').length) {
        //load plyr custom js
        var tag = document.createElement('script');
        tag.src = "/js/plyr_min_custom.js";
        document.body.appendChild(tag);
    }
    /* plyr stuff:end */
});


/* GOOGLE MAPS STUFF */

function initMap() {
    var map = new google.maps.Map(document.getElementById('new-map__map'),
        {
            zoom: 12,
            center: { lat: 47.7976883, lng: 13.057091 },
            streetViewControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: viewWidth < 768 ? google.maps.ControlPosition.RIGHT_BOTTOM : google.maps.ControlPosition.LEFT_TOP
            },
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_BOTTOM
            },
            scrollwheel: false,
        });

    var bounds = new google.maps.LatLngBounds();
    var filterItems = $(".new-map__item:not(.all)");
    var markers = [];
    var pin = {
        url: '/img/pin.png',
        scaledSize: { width: 47, height: 58 }
    };

    var infoWindow = new google.maps.InfoWindow({
        content: "",
        maxWidth: viewWidth < 768 ? 200 : 260
    });

    function showWindow(marker, content) {
        infoWindow.setContent(content);
        infoWindow.open(map, marker);
    }

    function itemClick() {
        var text = $(this).text();
        for (var i = 0; i < markers.length; i++) {
            if (markers[i].myName == text) {
                filter($(this), markers[i]);
                break;
            }
        }
    }
    filterItems.click(itemClick);

    $(".new-map__item.all").click(function () {
        map.fitBounds(bounds);
        infoWindow.close();
        $('.new-map__item').removeClass('active');
        $(this).addClass("active");
    });

    function markerClick(marker) {
        var name = marker.myName;
        var $elem = $(".new-map__item:contains('" + name + "')");
        filter($elem, marker);
    }

    function filter($elem, marker) {
        $('.new-map__item').removeClass('active');
        $elem.addClass("active");
        var mapImage = $elem.data("map-image");
        map.panTo(marker.getPosition());
        map.setZoom(14);
        var content = "<div class='map-info'><a href='" + $elem.data("ref")
            + "'><h3>" + $elem.data("address") + "</h3></a>";
        if (mapImage) {
            content += "<img src='" + mapImage + "' alt='" +
                $elem.data("address") + "'/>";
        }
        content += "<p>" + $elem.data("text") + "</p></div>";
        showWindow(marker, content);

        setRouteDestAdress($elem.data("address"));

    }

    function setRouteDestAdress(address) {
        if ($(".parking-route").length) {
            var dest_address = address;
            $(".dest-address").val(dest_address);
        }
    }

    filterItems.each(function () {
        var i = $(this);
        var pos = {
            lat: Number(i.data("latitude")),
            lng: Number(i.data("longitude"))
        };
        bounds.extend(pos);

        var marker = new google.maps.Marker({
            position: pos,
            map: map,
            icon: pin
        });

        marker.myName = i.text();

        google.maps.event.addListener(marker, 'click', (function (marker) {
            return function () {
                markerClick(marker);
            };
        })(marker));

        markers.push(marker);
    });

    map.fitBounds(bounds);

    function checkActiveItemOnLoad() {
        if (!$(".new-map__item.active").length) {
            var current_file_name = $(".new-map__locations").attr("data-current-file-name");
            $(".new-map__item").each(function () {
                var item_href = $(this).attr("data-ref");
                var item_address = $(this).attr("data-address");
                if (item_href == current_file_name) {
                    $(this).addClass("active");
                    setRouteDestAdress(item_address);
                }
            });
        }
        if (!$(".new-map__item.active").length) {  // if no item active, set first to active
            $(".new-map__item:first").addClass("active");
        }
    }
    checkActiveItemOnLoad();


    function itemActive() {
        var el = $(".new-map__item.active");
        var text = $(el).text();
        for (var i = 0; i < markers.length; i++) {
            if (markers[i].myName == text) {
                filter($(el), markers[i]);
                break;
            }
        }
    }
    setTimeout(function () { itemActive(); }, 500);
};

$('.qna__item').click(function (e) {
    e.preventDefault();
    $(this).addClass('active').siblings().removeClass('active');
});
