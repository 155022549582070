var ihavecookiesOptions = {
    cookieTypes: [
        {
            type: 'Präferenzen',
            value: 'preferences',
            description: 'Präferenz-Cookies ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte Sprache oder die Region in der Sie sich befinden. '
        },
        {
            type: 'Statistiken',
            value: 'analytics',
            description: 'Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten interagieren, indem Informationen anonym gesammelt und gemeldet werden.'
        },
        {
            type: 'Marketing',
            value: 'marketing',
            description: 'Marketing-Cookies werden verwendet, um Besuchern auf Webseiten zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.'
        }
    ],
    title: 'Diese Webseite verwendet Cookies',
    message: 'Wir verwenden Cookies, um die Nutzerfreundlichkeit der Website zu verbessern. Die dabei gespeicherten Daten sind keine personenbezogenen Daten und sind nicht auf Einzelpersonen rückführbar. Zu diesen Cookies zählen technisch notwendige Cookies, Cookies die zur Analyse der Nutzbarkeit der Seitenbedienelemente statistisch eingesetzt werden, sowie Cookies für Komforteinstellungen, die Ihnen die bestmögliche Nutzung unserer Website ermöglichen.',
    delay: 600,
    expires: 365,
    link: 'https://salk.at/Datenschutz.html',
    onAccept: function(){
        var myPreferences = $.fn.ihavecookies.cookie();
        console.log('Yay! The following preferences were saved...');
        console.log(myPreferences);
    },
    uncheckBoxes: true,
    acceptBtnLabel: 'Einverstanden',
    moreInfoLabel: 'Mehr Information',
    advancedBtnLabel: 'Einstellungen bearbeiten',
    cookieTypesTitle: 'Bitte wählen Sie aus, welche Cookies Sie akzeptieren:',
    fixedCookieTypeLabel: 'Betriebsnotwendig',
    fixedCookieTypeDesc: 'Sind betriebsbedingt notwendige Cookies.'
};

$(document).ready(function() {
    /* not used currently
    $('body').ihavecookies(ihavecookiesOptions);

    if ($.fn.ihavecookies.preference('marketing') === true) {
        console.log('This should run because marketing is accepted.');
    }

    $('#ihavecookiesBtn').on('click', function(){
        $('body').ihavecookies(ihavecookiesOptions, 'reinit');
    });
    */
});