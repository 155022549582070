if (!window.rsConf) { window.rsConf = {}; }
window.rsConf = {general: {usePost: true}};
if (!window.rsConf.ui) { window.rsConf.ui = {}; }
window.rsConf.ui.rsbtnClass = 'rsbtn_salk';
/*
// https://wrdev.readspeaker.com/configuration-api/callback
window.rsConf.cb = {
    ui: {
        open: function() {
            console.log("Abspielen angefangen");
        }
    }
}
*/  
/*
window.rsConf.ui.player = [
    '<span class="rsbtn_exp rsimg rspart"><a class="rsbtn_pause rsimg rspart" href="javascript:void(0);"><span class="rsbtn_btnlabel">Play</span></a><a class="rsbtn_stop rsimg rspart" href="javascript:void(0);"><span class="rsbtn_btnlabel">Stop</span></a><span class="rsbtn_player rsimg rspart"><span class="rsbtn_progress_container rsimg rsplaypart"><span class="rsbtn_progress_played rsimg" ></span><span class="rsbtn_progress_handle rsimg"><a href="javascript:void(0);" class="keyLink">&nbsp;</a></span></span><a class="rsbtn_volume rsimg rsplaypart" href="javascript:void(0);"><span class="rsbtn_btnlabel">Volume</span></a><span class="rsbtn_volume_container rsimg"><span class="rsbtn_volume_slider"><span class="rsbtn_volume_handle rsimg"><a href="javascript:void(0);" class="keyLink">&nbsp;</a></span></span></span><a class="rsbtn_settings rsimg rsplaypart" href="javascript:void(0);"><span class="rsbtn_btnlabel">Settings</span></a><a class="rsbtn_dl rsimg rsplaypart" href="javascript:void(0);"><span class="rsbtn_btnlabel">No Sound?</span></a></span><a class="rsbtn_closer rsimg rspart" href="javascript:void(0);"><span class="rsbtn_btnlabel">Close</span></a><span class="rsbtn_powered rsimg"><span class="rsbtn_btnlabel">Speech enabled by<a href="javascript:void(0);">ReadSpeaker</a></span></span></span>'
];
*/